<template>
  <div class="transfers-carousel">

    <b-row class="justify-content-center" align-v="center">
      <b-col class="desktop" cols="1">
        <b-row class="justify-content-center" align-v="center">
          <b-button class="pagination-button" variant="primary" @click.prevent="previousPage"
                    :disabled="filterQuery.page<=1">
            <img class="pagination-button-icon" src="@/assets/images/greater_than.svg" alt=""
                 style="transform: rotate(0.5turn);"/>
          </b-button>
        </b-row>
      </b-col>
      <b-col>
        <b-row class="scrolling-wrapper-flexbox-mobile" align-v="center">
          <b-col v-if="!hasRecords" class="justify-content-center">
            <b-row class="card-content justify-content-center empty-disclaimer text-center" align-v="center">
              <h3>{{ $t('transfers.no_available_transfers_informal') }}</h3>
            </b-row>
          </b-col>
          <b-col v-for="(transfer,i) in records" :class="{ 'card-first': i===0, 'card-last': i===records.length-1 }"
                 class="card justify-content-center"
                 @click="$router.push({name: 'app_flipcar', query: { transfer_id: transfer.id }})">
            <b-row class="card-content justify-content-center" align-v="center">
              <b-col>
                <b-row class="justify-content-center" align-v="center">
                  <b-img class="image" :src="transfer.vehicle.image_url" alt="Image 1"></b-img>
                </b-row>
                <b-row class="justify-content-center" align-v="center"><h3>{{ transfer.vehicle.name }}</h3></b-row>
                <b-row class="justify-content-center mt-1" align-v="center">
                  <div class="icon">
                    <span class="flipcoins">{{
                        transfer.vehicle_class.coins_cost <= 0 ? 'free' : transfer.vehicle_class.coins_cost
                      }}</span>
                  </div>
                  <div class="icon">
                    <span class="circle">{{ transfer.minimum_age }}+</span>
                  </div>
                  <b-img v-for="icon in transfer.icons" class="icon" :src="icon.image_url"
                         alt="/public/images/api/v1/vehicle/unknown.png"></b-img>
                </b-row>
              </b-col>
              <b-col>
                <b-row align-v="center">
                  <div>
                    <h3>{{ transfer.start_shop.address.city_name }}</h3>
                    <div>{{ transfer.start_shop.address.street_name_and_number }},
                      {{ transfer.start_shop.address.postcode }} {{ transfer.start_shop.address.city_name }}
                    </div>
                    <img class="icon-arrow" alt="image" src="@/assets/images/right_arrow_light.svg"/>
                    <h3>{{ transfer.end_shop.address.city_name }}</h3>
                    <div>{{ transfer.end_shop.address.street_name_and_number }}, {{
                        transfer.end_shop.address.postcode
                      }} {{ transfer.end_shop.address.city_name }}
                    </div>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="!isMobile() && records.length===1" class="desktop card justify-content-center" align-v="center"
                 style="visibility: hidden;"></b-col>
          <b-col v-if="hasMoreData" class="mobile">
            <b-button class="load-more-button" variant="primary" @click.prevent="nextPage()">
              {{ $t('button.more_transfers') }}
              <font-awesome-icon class="icon-arrow" icon="long-arrow-alt-right"/>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="desktop" cols="1">
        <b-row class="justify-content-center" align-v="center">
          <b-button class="pagination-button" variant="primary" @click.prevent="nextPage()" :disabled="!hasMoreData">
            <img class="pagination-button-icon" src="@/assets/images/greater_than.svg" alt=""/>
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <div class="desktop">
      <b-pagination
          v-show="recordsTotal > 0"
          v-model="filterQuery.page"
          :total-rows="recordsTotal"
          :per-page="filterQuery.per_page"
          size="lg"
          align="center"
          hide-goto-end-buttons
          prev-class="pagination-hidden"
          next-class="pagination-hidden"
          limit="5">
        <template #page="{ page, active }">
          <div class="pagination-circle"></div>
        </template>
        <template #ellipsis-text>
          <div class="pagination-circle" style="scale: 0.7;"></div>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>

import {getRecords} from "@/services/api";

export default {
  mounted() {
    window.addEventListener('resize', this.updateFilterQueryPerPage);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateFilterQueryPerPage);
  },
  data() {
    return {
      records: [],
      filterQuery: {
        page: 1,
        per_page: this.getPerPage()
      },
      recordsTotal: 0
    }
  },
  watch: {
    filterQuery: {
      immediate: true,
      deep: true,
      handler(oldV, newV) {
        this.downloadTransfers(this.filterQuery)
      }
    }
  },
  computed: {
    hasRecords() {
      return this.records.length > 0
    },
    hasMoreData() {
      return this.filterQuery["page"] * this.filterQuery["per_page"] < this.recordsTotal
    }
  },
  methods: {
    isMobile() {
      return window.innerWidth <= 768
    },
    getPerPage() {
      return this.isMobile() ? 10 : 2
    },
    updateFilterQueryPerPage() {
      this.filterQuery.per_page = this.getPerPage()
    },
    async downloadTransfers(filterQuery) {
      filterQuery["datatable"] = true
      filterQuery["distinct"] = ["start_shop_id", "end_shop_id", "vehicle_id"]
      const r = await getRecords('/public/transfers.json', filterQuery, {toggleProgressSpinner: true})
      this.recordsTotal = r["recordsTotal"]
      if (filterQuery["page"] <= 1 || !this.isMobile()) {
        this.records = r.data
      } else {
        this.records.push(...r.data)
      }
      return r.data
    },
    nextPage() {
      this.filterQuery.page += 1
    },
    previousPage() {
      this.filterQuery.page -= 1
    }
  }
}
</script>

<style scoped lang="scss">
.transfers-carousel {
  font-family: 'Poppins', Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
  padding: 0 15px; // adjust to -15 bootstrap margin
}

h3 {
  margin-top: 2px;
  margin-bottom: 2px;
  color: black;
}

.desktop {
  @media (max-width: 768px) {
    display: none;
  }
}

.mobile {
  @media (min-width: 768px) {
    display: none;
  }
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  -webkit-overflow-scrolling: touch; //Overflow Scrolling
  &::-webkit-scrollbar { // Scroll bars
    display: none;
  }

  .card {
    display: inline-block;
  }
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  -webkit-overflow-scrolling: touch; //Overflow Scrolling
  &::-webkit-scrollbar { // Scroll bars
    display: none;
  }
}

.scrolling-wrapper-flexbox-mobile {
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    -webkit-overflow-scrolling: touch; //Overflow Scrolling
    &::-webkit-scrollbar { // Scroll bars
      display: none;
    }
  }
}

.card {
  border-radius: 20px;
  margin: 20px 15px 50px 15px;
  padding: 10px 40px 10px 40px;
  z-index: 100;
  border: 1px solid #ddd;
  box-shadow: 1px 14px 52px -26px rgba(0, 0, 0, 0.75);

  cursor: pointer;

  @media (max-width: 768px) {
    flex: 0 0 auto;
    max-width: 80vw;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.card-first {
  @media (max-width: 768px) {
    margin-left: 30px;
  }
}

.card-last {
  @media (max-width: 768px) {
    margin-right: 30px;
  }
}

.card-content {
  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 10px;

  color: black;

  white-space: nowrap;

  @media (min-width: 768px) {
    //padding-right: 10px; // adjust to vehicle image whitespace
  }

  @media (max-width: 768px) {
    white-space: break-spaces;
  }
}

.empty-disclaimer {
  min-height: 20vw;
  @media (max-width: 768px) {
    min-height: 100vw;
    padding: 30px;
  }
}

.image {
  width: 15vw;

  @media (max-width: 768px) {
    width: 60vw;
  }
}

.icon-arrow {
  width: 2em;
  height: 2em;
  margin-top: 4px;
  color: #bcbcbc;
}

.icon {
  height: 2em;
  padding: 10px 0;
  margin: 3px;
  display: flex;
  align-items: center;
}

img.icon {
  background-color: #EDEDED;
  border-radius: 4px;
  padding: 3px 5px;
}

.flipcoins {
  border-radius: 25px;
  padding: 3px 10px 3px 10px;
  color: white;
  font-size: 8px;
  background-color: #ffc107;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
}

.circle {
  padding: 3px;
  color: white;
  font-size: 8px;
  text-align: center;
  background-color: black;
  border-radius: 50%;
  white-space: nowrap;
  font-weight: bold;
}

.load-more-button {
  border-radius: 25px;
  margin-right: 25px;
  padding: 3px 10px 3px 10px;
  color: white;
  font-size: 10px;
  background-color: #8000FF;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;

  .icon-arrow {
    margin-top: 0;
  }
}
</style>

<style>
#app {
  background-color: unset;
}

.pagination-button {
  padding: 0;
  border-radius: 50%;
  text-align: center;
  background-color: #8000FF !important;
  border-color: #8000FF !important;
}

.pagination-button-icon {
  width: 1.5vw;
  height: 1.5vw;
  margin: 20px;
}

.btn.disabled {
  background-color: #BEBEBE !important;
  border-color: #BEBEBE !important;
}

.pagination-circle {
  width: 15px;
  height: 15px;
  font-size: 8px;
  text-align: center;
  background-color: grey;
  border-radius: 50%;
  display: inline-block;
  white-space: nowrap;
}

.page-item .pagination-circle {
  background-color: #D9D9D9;
}

.page-item.active .pagination-circle {
  background-color: #8F00FF;
}

.page-link {
  color: white;
  background-color: unset !important;
  border: unset !important;
}

.pagination-hidden {
  display: none !important;
}
</style>